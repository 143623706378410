import React, { Component } from "react";
import Layout from '../components/layout';
import Cookies from "universal-cookie";

class Login extends Component {
  state = {
    username: "",
    password: "",
    error: false,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.username != "" && this.state.password != "") {
      if (this.state.username === process.env.SESSION_USER && this.state.password === process.env.SESSION_PASSWORD) {
        const cookies = new Cookies();
        cookies.set("studio", "allowed", { path: "/", maxAge: 86400 * 7 });
        window.location = "/home";
      } else {
        this.setState({ error: true })
      }
    } else {
      this.setState({ error: true })
    }
  }

  render() {
    return (
      <Layout>
        <div className="login-page">

          <div className="login__form">

            <h1>To access the website, please log in</h1>

            <form
              method="post"
              onSubmit={event => {
                this.handleSubmit(event)
              }}
            >
              <fieldset className="uk-fieldset">
                {this.state.error == true &&
                  <legend className="form__error-msg">Username or password incorrect</legend>
                }
                <div className="uk-margin">
                  <input className={`uk-input uk-form-width-large ${this.state.error == true ? 'uk-form-danger' : ''} `} type="text" name="username" onChange={this.handleUpdate} placeholder="Username" />
                </div>
                <div className="uk-margin">
                  <input className={`uk-input uk-form-width-large ${this.state.error == true ? 'uk-form-danger' : ''} `} type="password" name="password" onChange={this.handleUpdate} placeholder="Password" />
                </div>
                <button className="uk-button uk-button-primary" type="submit">Log In</button>
              </fieldset>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login